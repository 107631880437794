:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
}

div {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  color: #333;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: #e03525;
  text-decoration: underline;
}

p {
  font-size: 18px;
  line-height: 1.5em;
  margin: 1em 0;
}

img {
  max-width: 100%;
}

.form label {
  display: inline-block;
  margin-bottom: 5px;
}
.formItem {
  margin: 0 0 15px;
}
.formItem input,
.formItem textarea,
.formItem select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
}
.formItem textarea {
  height: 150px;
}
.pillButton {
  background-color: #e63d28;
  padding: 10px 30px;
  border-radius: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  display: inline-block;
  margin-top: 20px;
  justify-self: center;
  margin-bottom: 15px;
}

/* GRID */
.flexGridHalf {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flexGridHalf .col {
  width: 49%;
}

.flexGridThirds {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flexGridThirds .col {
  width: 32%;
}
.flexGridThirds .twoCol {
  width: 64%;
}
.submitEmailButton {
  cursor: pointer;
}
.success {
  background-color: rgb(161, 211, 161);
  color: rgb(37, 57, 37);
  font-weight: bold;
  padding: 10px;
  border: 1px solid rgb(37, 57, 37);
  border-radius: 5px;
  margin: 0 0 20px;
}

@media only screen and (max-width: 600px) {
  .main {
    width: 96%;
    padding: 0 2%;
  }
  .flexGridHalf {
    flex-direction: column;
  }
  .flexGridHalf .col {
    width: 100%;
  }
  .flexGridThirds {
    flex-direction: column-reverse;
  }
  .flexGridThirds .col {
    width: 100%;
  }
  .flexGridThirds .twoCol {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .main {
    width: 100%;
  }
}
