.class a:not(.donateButton) {
  color: #E03525;
  text-decoration: underline;
}
.donateButton, .popLinks a {
  background-color: #E63D28;
  padding: 10px 30px;
  border-radius: 40px;
  color: white;
  font-size: .8em;
  font-weight: bold;
  display: inline-block;
  margin-top: 20px;
  justify-self: center;
  margin-bottom: 15px;
}