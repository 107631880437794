.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: show;
  height: 100%;
  padding: 0 15%;
  margin: 0 auto;
}
.centerContent {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #fff;
}
.pageContent {
  padding: 50px;
  padding-bottom: 150px;
}

.path {
  fill: none;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s linear alternate infinite;
}

.launchContainer {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
  background-color: antiquewhite;
}
.launchContent {
  max-width: 100%;
  width: 800px;
}
.launchLogo {
  display: flex;
  flex: 1;
  justify-content: center;
}
.launchContainer .logoImage {
  max-width: 100%;
  width: 70%;
  margin-top: 75px;
  margin-bottom: 60px;
}
.launchContainer .launchText {
  margin-top: 20px;
}
.launchContainer hr {
  width: 50%;
  align-self: center;
}
.launchContent p,
.donateContainer p {
  font-size: 28px;
  margin-bottom: 1.5em;
  text-align: center;
  line-height: 1.5em;
  color: #333;
  padding: 0 15px;
}
.donateContainer {
  width: 100%;
}
.donateButton,
.popLinks a {
  background-color: #e63d28;
  padding: 10px 30px;
  border-radius: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  display: inline-block;
  margin-top: 20px;
  justify-self: center;
  margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
  .launchContainer .logoImage {
    margin-bottom: 45px;
  }
  .launchContent p {
    font-size: 20px;
    padding: 0 15px;
    text-align: justify;
  }
  .donateContainer p {
    font-size: 25px;
    margin-top: 10px;
  }
  .donateButton {
    width: 90%;
  }

  .main {
    width: 96%;
    padding: 0 2%;
  }
  .flexGridHalf {
    flex-direction: column;
  }
  .flexGridHalf .col {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .pageContent {
    padding: 50px 10px;
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 822;
  }
  to {
    stroke-dashoffset: 0;
  }
}
