.class {
  padding-bottom: 20px;
  margin-bottom: 40px;
  margin-right: 1%;
}
.class a:not(.donateButton) {
  color: #e03525;
  text-decoration: underline;
}
.donateButton,
.popLinks a {
  background-color: #e63d28;
  padding: 10px 30px;
  border-radius: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  display: inline-block;
  margin-top: 20px;
  justify-self: center;
  margin-bottom: 15px;
}
.classesGrid {
  justify-content: left;
}

/* .thumb1:hover { YOUR HOVER STYLES HERE } */
@media only screen and (max-width: 600px) {
  .classesGrid {
    flex-direction: column;
  }
}
