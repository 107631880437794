.logo {
  max-width: 270px;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.logoImage {
  max-width: 100%;
  position: relative;
  z-index: 0;
}

.svg {
  position: absolute;
  width: 100%;
  top: 20px;
  left: 0;
  max-height: 100%;
  z-index: 0;
  stroke: #e2998f;
  stroke-width: "4";
}

.path {
  fill: none;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 10s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 10000;
  }
  to {
    stroke-dashoffset: 0;
  }
}