.event {
    padding-bottom: 20px;
    margin-bottom: 40px;
    margin-right: 1%;
}

.eventsGrid {
    justify-content: left;
}

.thumb {
    width: 100%;
    padding-bottom: 100%;
    margin: 1em auto;
    overflow: hidden;
    position: relative;
    background: #000
}

.thumb img {
    position: absolute;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
}

.thumb:hover .title {
    opacity: 1;
}

.title {
    position: absolute;
    opacity: 0;
    transition: .5s;
    color: #fff;
    z-index: 1;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    padding: 10px;
    background-color: rgba(45, 24, 21, 0.824);
    display: flex;
    align-items: center;
    width: 100%;
}

.title h3 {
    text-align: center;
    width: 100%;
    text-decoration: none;
    color: #fff
}

/* .thumb1:hover { YOUR HOVER STYLES HERE } */
@media only screen and (max-width: 600px) {
    .eventsGrid {
        flex-direction: column;
    }
}