.nav {
  width: 100%;
  margin-top: 25px;
}
.nav ul {
  display: flex;
  list-style-type: none;
  margin-bottom: 5px;
  padding-left: 0;
}
.nav li {
  flex: 1;
  text-align: center;
  position: relative;
}
.nav a {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 10px;
  font-weight: bold;
  color: #e43c29;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 100%;
}
.nav a.active {
  color: #fff;
  background-color: #e43c29;
}
.navUnderline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: #e43c29;
  width: 100%;
  z-index: 0;
  border-radius: 100%;
}

/* top nav */
.topNav {
  position: fixed;
  top: 0;
  background-color: #e43c29;
  width: 100%;
  padding: 0 15%;
  z-index: 4;
  display: flex;
  justify-content: end;
  align-items: center;
}

.topNavLink {
  color: #fff;
  font-weight: bold;
  transition: 0.5s;
  padding: 10px;
}
.topNavActive {
  background-color: #e43c29;
  color: #fff;
}
.topNav .instagramIcon {
  max-height: 30px;
  margin-bottom: 10px;
  padding-top: 9px;
  margin-left: 10px;
  justify-self: center;
  vertical-align: middle;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .topNav {
    padding: 0 2%;
  }
}
